import React from "react";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const Index = () => {
  return (
    <>
      <GatsbySeo title="나의 당근 이야기" />
      <div></div>
    </>
  );
};

export default Index;
